var NycorApp = {}

$.fn.goTo = function () {
  $('html, body').animate({
    scrollTop: $(this).offset().top + 'px'
  }, 1000)
  return this
}

function onSubmitContact (token) {
  var form = document.getElementById('contact_form').submit();
  naja.uiHandler.submitForm(form)
}

NycorApp.init = function () {
  function LoaderExtension(naja, loaderSelector) {
    naja.addEventListener('init', function () {
      this.loader = document.querySelector(loaderSelector)
    }.bind(this))

    naja.addEventListener('complete', hideLoader.bind(this))

    function hideLoader (event) {
      this.loader.style.display = 'none'
      $('#submit').on('click', function (e) {
        e.preventDefault()
        $('.spinner-border').parent().prop('disabled', true)
        $('.spinner-border').show()
      })
    }

    return this
  }
  naja.registerExtension(LoaderExtension, '.spinner-border')
  naja.initialize()
  naja.snippetHandler.addEventListener('start', function (event) {
    if (event.snippet.id === 'snippet--contactForm') {
      var button = $('form').find('button')
      button.prop('disabled', true)
    }
  })
  naja.snippetHandler.addEventListener('afterUpdate', function (event) {
    $('form').ajaxValidation()
  })

  $('form').ajaxValidation()
}
