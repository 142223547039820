var initialize = function (form) {
  var request

  var errorHandler = function () {
    var groups = form.find('.form-group')
    groups.removeClass('has-success').removeClass('has-error').removeClass('has-warning')
    form.find('.error-container').empty()
  }

  var handler = function (event) {
    var input = $('#' + this.id)
    input.removeClass('is-invalid')
    input.removeClass('is-valid')
    input.addClass('validating')
    /*
    if (request && request.readyState !== 4) {
      request.abort()
    }
    */

    var values = form.serializeArray().reduce(function (obj, item) {
      obj[item.name] = item.value
      return obj
    }, {})
    values['_do'] = values['_do'].substring(0, values['_do'].length - 6) + 'validate'

    request = $.ajax({
      data: values,
      type: 'POST',
      dataType: 'json',
      success: function (data) {
        if (data.errors === undefined) {
          errorHandler()
          return
        }
        var container = $('#error__' + input.attr('id'))
        container.empty()
        Object.keys(data.errors).forEach(function (key) {
          if (key === input.attr('id')) {
            input.addClass('is-invalid')
            container.html(data.errors[key])
            input.data('validation-visited', true)
          }
        })
        if (!data.errors.hasOwnProperty(input.attr('id'))) {
          input.removeClass('is-invalid').addClass('is-valid')
        }
      },
      error: function (request, status) {
        if (status === 'abort') {
          return
        }
        errorHandler()
      },
    })
  }

  form.attr('novalidate', true)

  form.on('submit', function (e) {
    if (request) {
      request.abort()
    }
    errorHandler()
    form.off('blur change', 'input[type="text"], select, textarea', handler)
  })

  form.on('blur change', 'input[type="text"], select, textarea', handler)
}

$.fn.ajaxValidation = function () {
  return this.each(function () {
    initialize($(this))
  })
}